<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#UpdateUserInvitation"
      ref="openUpdateInviteModal"
      @click="dataReset()"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="UpdateUserInvitation">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Update user and status for {{ associationData.association_name }} - Association Portal</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeUpdateInviteuserAssociationModal"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <!-- Email Input -->
            <div class="form-group">
              <div class="input-wrapper">
                <input
                  id="email"
                  class="form-control disabled"
                  v-model.trim="$v.details.email.$model"
                  spellcheck="false"
                  autocomplete="off"
                  :class="{
                    'is-invalid': validationStatus($v.details.email),
                  }"
                  trim
                  required
                  type="text"
                  v-on:keyup="errorNull"
                />
                <span class="placeholder">Email</span>
                <div v-if="!$v.details.email.email" class="invalid-tooltip">
                  Please provide valid email
                </div>
              </div>
            </div>

            <!-- Role Dropdown -->
            <div class="form-group">
              <select class="form-control" id="role" v-model="selectedRole" :disabled="isLoadingArray[0]" @change="errorNull">
                <option value="">Select Role</option>
                <option value="User">Admin</option>
                <option value="Umpire">Umpire</option>
              </select>
            </div>

            <!-- Status Dropdown -->
            <div class="form-group">
              <select class="form-control" id="status" v-model="selectedStatus" :disabled="isLoadingArray[0]" @change="errorNull">
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>

          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              type="button"
              class="btn btn-primary w-100"
              block
              @click="updateInvites(0)"
              :disabled="isLoadingArray[0] || isDisabled"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import axios from "axios";
  import { email } from "vuelidate/lib/validators";
  
  export default {
    name: "UpdateRoleModal",
    data() {
      return {
        userInvites: {},
        isDisabled: true,
        isLoadingArray: [],
        email: "",
        selectedRole: "",
        selectedStatus: "",
        originalStatus: "",
        errorMessage: null,
        errorMessageEmail: null,
        successMessage: null,
        details: {
          email: "",
        },
      };
    },
    props: {
      associationData: {
        type: Object,
      },
    },
    validations: {
      details: {
        email: { email },
      },
    },
   
methods: {
  validationStatus: function (validation) {
    return typeof validation != "undefined" ? validation.$error : false;
  },
  errorNull: function () {
    // this.errorMessage = null;
    this.errorMessageEmail = null;
    if (
      (this.details.email == "" || this.selectedRole == "" ) ||
      this.$v.$invalid
    ) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  },
  dataReset: function () {
    this.isDisabled = true;
    this.details.email = this.userInvites.target || "";
    this.selectedRole = this.userInvites.level === "3" ? "User" : "Umpire";
    
    // Check if user_association_role exists and has a status
    if (this.userInvites.user_association_role && this.userInvites.user_association_role.status != null) {
      this.selectedStatus = this.userInvites.user_association_role.status == 1 ? "1" : "0";
    } else {
      this.selectedStatus = "0"; // Set a default value if status is not available
    }

    this.originalStatus = this.selectedStatus; // Save the original status
  },

async updateInvites(index) {
    this.isDisabled = true;
    this.$v.$touch();
    if (this.$v.$pending || this.$v.$error) return;

    let level = this.selectedRole === 'User' ? 3 : 4;
    let status = this.selectedStatus; // Get the updated status value
    
    // Check if the status is changed and show confirmation alert
    if (this.originalStatus !== this.selectedStatus) {
        let actionText = this.selectedStatus === "1" ? "activate" : "deactivate";
        let confirmationMessage = `About to ${actionText} ${this.details.email}. Continue?`;
        
        try {
           // User confirmed, start loading
            // Show confirmation dialog using this.$confirm
            await this.$confirm(confirmationMessage);

            // If confirmed, proceed with the API call
            this.sendInviteUpdate(index, level, status);
        } catch (error) {
          this.dataReset();
            // If canceled, reset loading state
            this.$set(this.isLoadingArray, index, false);
        }
    } else {
        // No status change, directly call the API
        this.sendInviteUpdate(index, level, status);
    }
},

// Extracted function to handle API call
sendInviteUpdate(index, level, status) {
    let formData = new FormData();
    formData.append('invite_id', this.userInvites.id);
    formData.append('association_id', this.associationData.id);
    formData.append('new_level', level);
    formData.append('status', status); 
    
    axios.post(process.env.VUE_APP_API_URL + 'association/updateInviteUserRole', formData)
      .then(response => {
        if (response.data.statusCode === 200) {
          
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
          }, 1000);
          this.$root.$emit("userAssociationAction");
          var elem = this.$refs.closeUpdateInviteuserAssociationModal;
          if (elem) {
            elem.click();
          }
        } else if (response.data.statusCode === 403) {
          this.$alert(response.data.message, 'Error');
          {
            this.$root.$emit("userAssociationAction");
            var elem1 = this.$refs.closeUpdateInviteuserAssociationModal;
            if (elem1) {
              elem1.click();
            }
          }
        } else if (response.data.statusCode === 404) {
          var elem2 = this.$refs.closeUpdateInviteuserAssociationModal;
            if (elem2) {
              elem2.click();
            }
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        }
      })
        .catch(error => {
            console.error("Error sending invitation", error);
        })
        .finally(() => {
            this.isLoadingArray[index] = false;
            this.dataReset();
        });
    this.$set(this.isLoadingArray, index, true);
}

},
  mounted() {
    this.$root.$on("updateUsersInviteModal", (data) => {
        this.userInvites = data;
        this.dataReset();
        var elem = this.$refs.openUpdateInviteModal;
        if (elem) {
        elem.click();
        } 
      });
        },
    };
</script>
<style scoped>
  .sub-setting-options {
      top: -173%;
      right: 28px;
  }
</style>