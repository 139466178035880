<template>
  <div>
    <UserInviteModal :associationData="associationData" />
    <UpdateRoleModal :associationData="associationData" />

    <div class="event-section pt-0">
      <div :class="['sticky-header', { scrolled: isScrolled }]">
        <div class="users-invite-top-sec">
          <p>{{ totalUsers }} <span>users</span></p>
          <div class="add-new-team-btn position-relative" @click="openUserInviteModal" v-if="currentIsAdmin == '1'">
            <button type="button">
              <img :src="inviteUser" alt="invite-user" />
              <span>Invite User</span>
            </button>
          </div>
        </div>
        <div class="filter-top">
          <div class="filter-container">
            <div class="filter-cotent justify-content-end">
              <button v-for="option in userStatusOptions" :key="option.value"
                @click="filter.userStatus = option.value; applyUserStatusFilter()" class="dropdown-button"
                :class="{ 'has-value': filter.userStatus === option.value }">
                {{ option.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="event-section-content">
        <b-row class="no-gutters" v-if="users.length > 0 && showLoader == 0">
          <div class="col-12 products-column">
            <div class="event-card">
              <div class="list-container">
                <ul class="user-list mb-0">
                  <li v-for="user in computedUsers" :key="user.id" class="user-item">
                    <div class="user-info-left">
                      <div class="user-img">
                        <div class="users-notification" v-if="imgLoader == 1">
                          <!-- <div class="lds-dual-ring-refresh"></div> -->
                          <img src="../../assets/loader.gif" alt="" />
                        </div>
                        <img @load="onImageLoad" @error="setDefaultImage" v-if="user?.user_linked?.id"
                          :src="`${API_URL}${user?.user_linked?.id}/profile_picture.jpg?v=${time}`"
                          alt="linked profile picture" />
                        <img @load="onImageLoad" v-else src="../../assets/Svg/member.svg" alt="" />
                      </div>
                      <div>
                        <div class="user-info-left-top">
                          <!-- Name -->
                          <div class="user-name-sec">
                            <template v-if="user.user_linked && user.user_linked.name">
                              {{ user.user_linked.name }}
                            </template>
                            <template v-else-if="user.user_profile">
                              {{ user.user_profile.fname || '' }} {{ user.user_profile.lname || '' }}
                            </template>
                            <template v-else>
                              {{ user.fname || '' }} {{ user.lname || '' }}
                            </template>
                            <template v-if="!user.user_linked && !user.user_profile && !user.fname && !user.lname">
                              N/A
                            </template>
                          </div>

                          <!-- Status -->

                          <span :class="user.status == 0 ? 'accept-btn' : 'pending-btn'">
                            {{ user.status == 0 ? 'Accepted' : 'Pending' }}
                          </span>

                          <span v-if="user.user_association_role && user.user_association_role.status !== null">
                            <span v-if="user.user_association_role.status == 1" class="user-active">Active</span>
                            <span v-else class="user-inactive">Inactive</span>
                          </span>

                          <!-- Role -->
                          <div class="user-role">
                            {{ user.level == 3 ? "Admin" : "Umpire" }}
                          </div>
                        </div>

                        <!-- Email -->
                        <div class="user-email">
                          <template v-if="user.user_profile && user.user_profile.email">
                            {{ user.user_profile.email }}
                          </template>
                          <template v-else-if="user.email">
                            {{ user.email }}
                          </template>
                          <template v-else-if="user.target">
                            {{ user.target }}
                          </template>
                          <template v-else>
                            <span class="text-muted">No email available</span>
                          </template>
                        </div>
                      </div>
                    </div>

                    <!-- Actions -->
                    <div class="user-actions" v-if="currentIsAdmin == '1'">
                      <button v-if="user.status == 1" class="btn invite-btn" @click="cancelInvite(user)">
                        Cancel Invite
                      </button>
                      <button v-if="user.status == 1" class="btn invite-btn" @click="resendInvite(user)">
                        Resend Invite
                      </button>

                      <div class="edit-options" @click.stop="toggleEditEventOption(user)"
                        v-if="(user.status == 0 || user.status == 3) && currentIsAdmin == '1'">
                        <div class="opt edit-btn mb-0">
                          <img src="../../assets/Svg/ellipse.svg" alt="" />
                          <div class="sub-setting-options edit-event-options"
                            v-if="editEventOptionVisible && selectedItem === user">
                            <ul class="d-block pb-0 text-left">
                              <li class="cursor-pointer" @click="editInviteRole(user)">Edit Role</li>
                              <li class="cursor-pointer" @click="deleteInvite(user)">Remove User</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>

                <content-placeholders :rounded="true" v-if="showLoader1">
                  <content-placeholders-heading :img="false" />
                </content-placeholders>
              </div>
            </div>
          </div>
        </b-row>

        <div v-if="users.length" v-observe-visibility="handleScrolledToButtom"></div>
        <div class="no-event-wrap" v-if="(!users || users.length === 0) && showLoader === 0">
          <img src="../../assets/Svg/no-data.svg" alt />
          <p class="header">No users found.</p>
          <p class="info">
            There are no association users to display in the selected category.
          </p>

        </div>
        <div class="products-list-shim pa-3" v-if="users.length == 0 && showLoader == 1">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
          <content-placeholders class="mt-3" :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
import UserInviteModal from "./UserInviteModal.vue";
import UpdateRoleModal from "./Modals/UpdateRoleModal.vue";
import axios from "axios";

export default {
  name: "AssociationUsersDetail",
  data() {
    return {
      filter: {
        userStatus: "active", // Stores the selected filter value
        eventType: "",  // Keeps event type if needed
      },
      userStatusOptions: [
        { value: "all", label: "All" },
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
      associationGuid: "",
      selectedItem: null,
      editEventOptionVisible: false,
      fields: [
        // { key: "status", label: "Status", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "target", label: "Email", sortable: true },
        { key: "roleName", label: "Role", sortable: true },
        { key: "action", label: "Action", class: "text-center" },
      ],
      inviteUser: require("../../assets/Svg/invite-user.svg"),
      isScrolled: false,
      imgLoader: 1,
      API_URL: this.$userProfileStorageURL,
      time: this.$time,
    };
  },
  props: {
    currentIsUmpire: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    associationData: {
      type: Object,
    },
    users: {
      type: Array,
    },
    lastPage: {
      type: Number,
    },
    page: {
      type: Number,
    },
    showLoader: {
      type: Number,
    },
    showLoader1: {
      type: Boolean,
    },
    totalUsers: {
      type: Number
    }
  },
  components: {
    UserInviteModal,
    UpdateRoleModal,
  },
  computed: {
    computedUsers() {
      return this.users.map(user => ({
        ...user,
        name: this.getUserName(user) // Use method to extract name
      }));
    }
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    setDefaultImage(event) {
      event.target.src = require('../../assets/Svg/member.svg'); // Set default image on error
    },
    getUserName(user) {
      if (user.user_linked && user.user_linked.name) {
        return user.user_linked.name;
      } else if (user.user_profile) {
        return `${user.user_profile.fname || ''} ${user.user_profile.lname || ''}`.trim();
      } else {
        return `${user.fname || ''} ${user.lname || ''}`.trim() || 'N/A';
      }
    },
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 5;
    },
    toggleEditEventOption(item) {
      if (this.selectedItem === item && this.editEventOptionVisible) {
        this.editEventOptionVisible = false;
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
        this.editEventOptionVisible = true;
      }
    },
    closeEditEventOption(event) {
      // Check if the clicked element is inside the dropdown
      if (!this.$el.contains(event.target)) {
        this.editEventOptionVisible = false;
        this.selectedItem = null;
      }
    },
    applyUserStatusFilter() {
      this.$root.$emit("filter-applied", this.filter.userStatus);
    },
    clearUserStatusFilter() {
      this.filter.userStatus = "active";
      this.applyUserStatusFilter();
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.$root.$emit("page-increment"); // Emit event to notify parent component
      }
    },
    openUserInviteModal() {
      this.$root.$emit("usersInviteModal");
    },
    editInviteRole(item) {
      this.$root.$emit("updateUsersInviteModal", item);
    },
    async cancelInvite(usersData) {
      try {
        await this.$confirm(
          `About cancel the invite to user ${usersData.fname ? usersData.fname : ""} ${usersData.lname ? usersData.lname : ""} (${usersData.target}). Continue?`
        );

        const inviteData = {
          invite_type: usersData.invite_type,
          level: usersData.level,
          association_id: usersData.association_id,
          email: usersData.target,
          invite_id: usersData.id,
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}association/cancelUserInvite`,
          { inviteData }
        );
        if (response.data.statusCode === 403) {
          this.$alert(response.data.message)

          this.$root.$emit("userAssociationAction");
          return;
        } else if (response.data.statusCode === 404) {
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        }

        this.$root.$emit("userAssociationAction");
      } catch (error) {
        console.error("Error cancelling invite:", error);
        // Optionally handle the error or show a notification to the user
      }
    },

    async deleteInvite(item) {
      this.$confirm(
        `About to remove user ${item.fname ? item.fname : ""} ${item.lname ? item.lname : ""} (${item.target}) from association. Continue?`
      ).then(() => {
        const formData = new FormData();
        formData.append('association_id', item.association_id);
        formData.append('users_id', item.user_link);
        formData.append('invite_id', item.id);

        axios.post(
          `${process.env.VUE_APP_API_URL}association/deleteUserFromAssociation`,
          formData
        )
          .then(response => {
            if (response.data.statusCode === 200) {
              if (response.data.data.accessAvailable === false) {
                this.$router.push({ name: "Teams" });
              } else {
                this.$root.$emit("userAssociationAction");
                this.$alert(response.data.message);
              }
            }
            else if (response.data.statusCode === 403) {
              this.$alert(response.data.message);
              this.$root.$emit("userAssociationAction");
            } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
            } else {
              this.$alert(response.data.message);
            }
          })
          .catch(error => {
            console.error("Error removing user from association", error);
            this.$alert("An error occurred while removing the user from the association.");
          });
      });
    },
    async resendInvite(item) {
      this.$confirm(
        `About to resend the invite to user ${item.fname ? item.fname : ""} ${item.lname ? item.lname : ""} (${item.target}). Continue?`
      ).then(() => {
        let reInviteData = {
          invite_type: item.invite_type,
          level: item.level,
          association_id: item.association_id,
          email: item.target,
          userId: item.user_link,
        };

        axios.post(
          `${process.env.VUE_APP_API_URL}association/reInviteUser`,
          { reInviteData: reInviteData }
        )
          .then(response => {
            if (response.data.statusCode === 200) {
              this.$alert(response.data.message);
              this.$root.$emit("userAssociationAction");
            } else if (response.data.statusCode === 403) {
              this.$alert(response.data.message);
              this.$root.$emit("userAssociationAction");
            } else if (response.data.statusCode === 404) {
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
            } else {
              this.$alert("Unexpected error occurred while resending invite.");
            }
          })
          .catch(error => {
            console.error("Error resending invitation", error);
            this.$alert("An error occurred while resending the invitation.");
          });
      });
    }
  },

  mounted() {
    document.addEventListener("click", this.closeEditEventOption);
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeEditEventOption);

    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
}
</script>
<style scoped>
.pending-btn {
  border-radius: 15px;
  background: #CFD7E0;
  color: #333333;
  width: 61px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #B9C6D3;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.accept-btn {
  border-radius: 15px;
  background: #188EF5;
  color: white;
  width: 61px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.invite-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: right;
  color: #188EF5;
}

.delete-btn img {
  height: 20px;
}

.user-active {
  background: #DFFFEA;
  width: 61px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 0.5px solid #17C653;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #17C653;
}

.user-role {
  background: #E5F2FE;
  width: 61px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 0.5px solid #188ef5;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #188ef5;
}

.user-inactive {
  background: #dc3545c9 !important;
  color: white;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 15px;
}
</style>