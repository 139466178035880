<template>
  <div class="secondary-left-menu">
    <div class="switch-btn" @click="showAssociation" v-click-outside="hideAssociation">
      <span>Switch Association</span>
      <p class="association-data" v-if="receivedData.association_name">{{ receivedData.association_name }}</p>
      <p v-else>
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="1" class="m-0" style="width: 150px" />
        </content-placeholders>
      </p>
    </div>

    <div class="sub-setting-options" v-if="associationList">
      <ul>
        <li v-for="association in associationData" :key="association.guid"
          @click="navigateToAssociationEvents(association)"
          :class="{ 'disabled-association': association.guid === receivedData.guid }">
          {{ association.association_name }}
        </li>
      </ul>
    </div>
    <ul class="left-menu-list">
      <!-- Events -->
      <router-link class="mx-0" :to="{
        name: 'AssociationEvents',
        params: { associationGuid: $route.params.associationGuid },
      }">
        <li class="left-list-item" v-bind:class="[openTabSet == 1 ? 'active' : '']">
          <div class="list-item-content w-100">
            <div class="item-content-image">
              <img v-if="openTabSet == 1" :src="event_active" />
              <img v-else :src="event_inactive" />
            </div>
            <span>Events</span>
          </div>
        </li>
      </router-link>

      <!-- Users -->
      <router-link class="mx-0" :to="{
        name: 'AssociationUsers',
        params: { associationGuid: $route.params.associationGuid },
      }">
        <li class="left-list-item" v-bind:class="[openTabSet == 2 ? 'active' : '']"
          v-if="currentIsAdmin == 1 || currentIsAdmin == '1'">
          <div class="list-item-content w-100">
            <div class="item-content-image">
              <img v-if="openTabSet == 2" :src="team_active" />
              <img v-else :src="team_inactive" />
            </div>
            <span>Users</span>
          </div>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/eventBus";

export default {
  name: "LeftMenuSecondaryAssociation",
  data() {
    return {
      event_active: require("../../assets/Svg/calendar-blue.svg"),
      event_inactive: require("../../assets/Svg/calendar-grey.svg"),
      team_active: require("../../assets/Svg/team-blue.svg"),
      team_inactive: require("../../assets/Svg/team-grey.svg"),
      associationtLoader: 1,
      associationData: [],
      inviteCount: '',
      associationList: false,
      receivedData: {},
      showLoader: 0
    };
  },
  props: {
    openTabSet: {
      type: Number,
    },
    currentIsAdmin: {
      type: [Number, String],
    },
  },

  methods: {
    navigateToAssociationEvents(association) {
      this.receivedData.association_name = "";
      this.$root.$emit("associationRoute", association);
      this.$router.push({ name: 'AssociationEvents', params: { associationGuid: association.guid } });
    },
    showAssociation() {
      this.associationList = !this.associationList; // Toggle the value
    },
    hideAssociation() {
      this.associationList = false; // Toggle the value
    },
    fetchUserAssociationData() {
      this.associationtLoader = 1;
      axios
        .post(process.env.VUE_APP_API_URL + "association/checkUserAssociations")
        .then((response) => {
          this.associationData = response.data.data.associations;
          this.inviteCount = response?.data?.data?.invite_sum;
          this.associationtLoader = 0;
        })
        .catch((error) => {
          console.error("Error fetching association names:", error);
          this.associationtLoader = 0;
        });
    },
  },
  mounted() {
    EventBus.$on("associationData", (data) => {
      this.receivedData = data;
    });
    this.$root.$on("newAssociationAdded", () => {
      this.fetchUserAssociationData();
    })
    this.fetchUserAssociationData();
  },
};
</script>
