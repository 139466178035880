var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secondary-left-menu"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideAssociation),expression:"hideAssociation"}],staticClass:"switch-btn",on:{"click":_vm.showAssociation}},[_c('span',[_vm._v("Switch Association")]),(_vm.receivedData.association_name)?_c('p',{staticClass:"association-data"},[_vm._v(_vm._s(_vm.receivedData.association_name))]):_c('p',[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{staticClass:"m-0",staticStyle:{"width":"150px"},attrs:{"lines":1}})],1)],1)]),(_vm.associationList)?_c('div',{staticClass:"sub-setting-options"},[_c('ul',_vm._l((_vm.associationData),function(association){return _c('li',{key:association.guid,class:{ 'disabled-association': association.guid === _vm.receivedData.guid },on:{"click":function($event){return _vm.navigateToAssociationEvents(association)}}},[_vm._v(" "+_vm._s(association.association_name)+" ")])}),0)]):_vm._e(),_c('ul',{staticClass:"left-menu-list"},[_c('router-link',{staticClass:"mx-0",attrs:{"to":{
      name: 'AssociationEvents',
      params: { associationGuid: _vm.$route.params.associationGuid },
    }}},[_c('li',{staticClass:"left-list-item",class:[_vm.openTabSet == 1 ? 'active' : '']},[_c('div',{staticClass:"list-item-content w-100"},[_c('div',{staticClass:"item-content-image"},[(_vm.openTabSet == 1)?_c('img',{attrs:{"src":_vm.event_active}}):_c('img',{attrs:{"src":_vm.event_inactive}})]),_c('span',[_vm._v("Events")])])])]),_c('router-link',{staticClass:"mx-0",attrs:{"to":{
      name: 'AssociationUsers',
      params: { associationGuid: _vm.$route.params.associationGuid },
    }}},[(_vm.currentIsAdmin == 1 || _vm.currentIsAdmin == '1')?_c('li',{staticClass:"left-list-item",class:[_vm.openTabSet == 2 ? 'active' : '']},[_c('div',{staticClass:"list-item-content w-100"},[_c('div',{staticClass:"item-content-image"},[(_vm.openTabSet == 2)?_c('img',{attrs:{"src":_vm.team_active}}):_c('img',{attrs:{"src":_vm.team_inactive}})]),_c('span',[_vm._v("Users")])])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }